
import { Component, Vue, Watch } from "vue-property-decorator";
import UploadExcel from "@/components/UploadExcel/index.vue";
import GoodsStockEdit from "@/views/goods/components/goodsStockEdit.vue";
import SupplierGoodsSelector from "@/pages/goods/components/supplier-goods-selector/supplier-goods-selector.vue";
import XTable from "@/components/x-table/x-table.vue";
import {
  $goodsList,
  $goodsListTemplate,
  GoodsItemProxy,
} from "@/pages/goods/services/goods-list";
import { $goodsCategory } from "@/pages/goods/services/goods-category";
import {
  $xTable,
  XTableFormValidate,
  XTableStdResponse,
} from "@/components/x-table/table.proxy";
import { Foundation } from "@/../ui-utils";
import { exportSheet } from "@/pages/goods/services/utils";
import * as API_goods from "@/api/goods";
import XDialog from "@/components/x-dialog/x-dialog.vue";
import { $xDialog } from "@/components/x-dialog/dialog.proxy";
import quantity_icon from "@/assets/button_icon/quantity.png";// 库存
import edit_icon from "@/assets/button_icon/edit.png";// 编辑
import delete_icon from "@/assets/button_icon/delete.png";// 删除
import up_icon from "@/assets/button_icon/up.png";// 上架
import down_icon from "@/assets/button_icon/down.png";// 下架
import { unixToDate } from "../../../../../ui-utils/Foundation";
@Component({
  name: "goods-list",
  components: {
    SupplierGoodsSelector,
    GoodsStockEdit,
    UploadExcel,
    XTable,
    XDialog,
  },
})
export default class GoodsList extends Vue {
  quantity_icon = quantity_icon;
  edit_icon = edit_icon;
  delete_icon = delete_icon;
  up_icon = up_icon;
  down_icon = down_icon;
  importShow = false;
  fileList = [];
  isShowUnderReason = false;
  under_reason = "";
  jdImportName = "商品SKU";
  jdImportLoading = false;
  jdImportTimer: any;
  areaNum = 1;
  provinceList = [];
  cityList = [];
  countyList = [];
  townList = [];
  areaSelectData = {
    province: "",
    city: "",
    county: "",
    town: "",
    jd_area_state: 0,
  };

  @Watch("areaSelectData", { deep: true, immediate: true })
  watchAreaSelect(newValue) {
    const _this: any = this;
    const data = JSON.parse(JSON.stringify(newValue));
    data.isJDSupplier = _this.isJDSupplier;
    console.log(_this);

    console.log(newValue, 'watchAreaSelect');

    sessionStorage.setItem("areaSelectHandle", JSON.stringify(data));
  }

  @Watch("areaSelectData.province", { immediate: true, deep: true })
  watchProvince(newValue) {
    console.log(newValue)
    if (newValue === "") {
      this.areaSelectData.jd_area_state = 0
    }
    this.areaSelectData.city = "";
    this.areaSelectData.county = "";
    this.areaSelectData.town = "";
    if (newValue === "") {
      this.areaNum = 1;
      this.getAreaHandle("province");
    } else {
      this.areaNum = 2;
      this.getAreaHandle("city", { id: newValue });
    }
  }

  @Watch("areaSelectData.city", { deep: true })
  watchCity(newValue) {
    this.areaSelectData.county = "";
    this.areaSelectData.town = "";
    if (this.areaSelectData.province === "") return;
    if (newValue === "") this.areaNum = 2;
    else {
      this.areaNum = 3;
      this.getAreaHandle("county", { id: newValue });
    }
  }

  @Watch("areaSelectData.county", { deep: true })
  watchCounty(newValue) {
    this.areaSelectData.town = "";
    if (this.areaSelectData.city === "") return;
    if (newValue === "") this.areaNum = 3;
    else {
      this.areaNum = 4;
      this.getAreaHandle("town", { id: newValue });
    }
  }

  @Watch("importShow")
  watchImportShow(newValue) {
    if (newValue) this.exportDialog.display();
    else this.exportDialog.dismiss();
    this.exportDialog.visible = newValue;
  }

  private mounted() {
    this.init();
    console.log(222222222222222);
  }

  private beforeDestroy() {
    if (this.jdImportTimer) clearTimeout(this.jdImportTimer);
  }

  // 表头部重新渲染
  renderHeader(h, { column, $index }) {
    return h('span', column.label);
  }

  // 编辑多规格商品
  async editSpecGoods(row) {
    if (row.good_source !== 1) { // 不是京东商品
      // 编辑多规格商品是否全部下架
      const offShelf = await API_goods.getGoodsBySupperId(row.raw.goods_id)
      if (offShelf) {
        // 全部下架就跳转到编辑页面
        row.edit().then(() => this.tableProxy.request())
      } else {
        this.$message.error('该商品有未下架的规格，请先下架');
      }
    }
  }

  init() {
    if ((this as any).isJDSupplier) {
      this.jdImportLoading = true;
      this.getJdFileState();
    }
  }

  /**
   * 获取区域数据
   * @param type
   * @param data
   */
  getAreaHandle(type, data: any = {}) {
    if (type === "province") {
      API_goods.getJDProvince().then((res) => {
        this.provinceList = this.dataHandle(res.data.result);
      });
    } else if (type === "city") {
      API_goods.getJDCity(data.id).then((res) => {
        this.cityList = this.dataHandle(res.data.result);
      });
    } else if (type === "county") {
      API_goods.getJDCounty(data.id).then((res) => {
        this.countyList = this.dataHandle(res.data.result);
      });
    } else if (type === "town") {
      API_goods.getJDTown(data.id).then((res) => {
        this.townList = this.dataHandle(res.data.result);
      });
    }
  }

  dataHandle(data) {
    const returnData: any = [];
    const keys = Object.keys(data);
    const values = Object.values(data);
    keys.forEach((key, index) => {
      const data = {
        name: key,
        id: values[index],
      };
      returnData.push(data);
    });
    return returnData;
  }

  cakeCategory = $goodsCategory.create('9');// 蛋糕供应商只看蛋糕分类
  category = $goodsCategory.create();

  tableProxy = $xTable.create({
    key: "sn",
    search: {
      formData() {
        return {
          category_path: "",
          is_auth: "",
          supplier_enable: "",
          ship_method: "",
          keyword: "",
          goods_ptype: "",
          low_supprice: "",
          high_supprice: "",
          goods_name: "",
          goods_sn: "",
          key_word: "goods_name",
        };
      },

      rules(ctx): { [p: string]: XTableFormValidate[] } {
        return {
          profit: [
            {
              trigger: ["blur"],
              validator: (rule, value, callback) => {
                if (!ctx.formData.profit_min && !ctx.formData.profit_max) {
                  return callback();
                }
                if (
                  typeof ctx.formData.profit_min === "string" ||
                  typeof ctx.formData.profit_min === "string"
                ) {
                  return callback(new Error("区间输入框内只允许输入数字"));
                }

                if (ctx.formData.profit_min > ctx.formData.profit_max) {
                  return callback(new Error("最低毛利率不可大于最高毛利率"));
                }

                callback();
              },
            },
          ],
          market: [
            {
              trigger: ["blur"],
              validator: (rule, value, callback) => {
                if (!ctx.formData.low_mktprice && !ctx.formData.high_mktprice) {
                  return callback();
                }
                if (
                  typeof ctx.formData.low_mktprice === "string" ||
                  typeof ctx.formData.high_mktprice === "string"
                ) {
                  return callback(new Error("区间输入框内只允许输入数字"));
                }

                if (ctx.formData.low_mktprice > ctx.formData.high_mktprice) {
                  return callback(new Error("最低市场价不可大于最高市场价"));
                }

                callback();
              },
            },
          ],
        };
      },
      onSortChange(parentSort, prop, order) {
        const _order = order === "ascending" ? "asc" : "desc";
        return order ? { name: prop, order: _order } : {};
      },
    },
    onRequest(params: any): Promise<XTableStdResponse> {
      const area: any = sessionStorage.getItem("areaSelectHandle");
      if (
        area &&
        JSON.parse(area).province !== "" &&
        JSON.parse(area).isJDSupplier
      ) {
        let jd_area = "" + JSON.parse(area).province + "_";
        if (JSON.parse(area).city !== "") {
          jd_area += JSON.parse(area).city + "_";
        } else jd_area += "0_";
        if (JSON.parse(area).county !== "") {
          jd_area += JSON.parse(area).county + "_";
        } else jd_area += "0_";
        if (JSON.parse(area).town !== "") jd_area += JSON.parse(area).town;
        else jd_area += "0";
        params.search.jd_area = jd_area;
        console.log(area, 584);

        params.search.jd_area_state = JSON.parse(area).jd_area_state;
      } else {
        delete params.search.jd_area_state
        delete params.search.jd_area
      }
      if (params.search.key_word === "goods_name") {
        params.search.goods_name = params.search.keyword;
        delete params.search.goods_sn;
      } else {
        params.search.goods_sn = params.search.keyword;
        delete params.search.goods_name;
      }
      const searchData = {
        ...params.search
      }
      delete searchData.keyword;
      delete searchData.key_word;
      // delete params.search.keyword
      return $goodsList.list({
        page_no: params.pageIndex,
        page_size: params.pageSize,
        shopType: 2,
        ...searchData,
        sort: params.sort.name
          ? `${params.sort.name}_${params.sort.order}`
          : "def_desc",
      });
    },
  });

  exportDialog = $xDialog.create({
    title: "批量导入",
    width: "500px",
    showClose: true,
    displayFooterExtras: true,
    beforeConfirm: () => {
      this.handleImportedFileData();
      return false;
    },
    beforeCancel: () => {
      this.closeImportDialog();
      return true;
    },
    beforeClose: () => {
      this.closeImportDialog();
      return true;
    },
  });

  batchOperation(type) {
    const areaSelectHandle: any = sessionStorage.getItem("areaSelectHandle");
    const isJDSupplier: any = JSON.parse(areaSelectHandle).isJDSupplier;
    let idList: any = null;
    idList = this.tableProxy
      .exportSelectedData()
      .map((item) => {
        return item.id;
      })
      .join(",");
    if (!idList.length) return this.$message.error("请勾选至少一件商品");
    try {
      switch (type) {
        case 0:
          $goodsList.offShelf(idList, {}, isJDSupplier).then(() => {
            this.tableProxy.request();
            this.tableProxy.select.reset(null!, false);
          });
          break;
        case 1:
          $goodsList.remove(idList, {}).then(() => {
            this.tableProxy.request();
            this.tableProxy.select.reset(null!, false);
          });
          break;
        case 2:
          $goodsList.upperGoods(idList, {}, isJDSupplier).then(() => {
            this.tableProxy.request();
            this.tableProxy.select.reset(null!, false);
          }, () => {
            this.tableProxy.request();
            this.tableProxy.select.reset(null!, false);
          });
          break;
      }
      // this.tableProxy.select.reset(null!, false)
      console.log(111);
    } catch (error) {
      console.log(222);
    }
  }

  /** 显示拒绝原因 */
  showUnderReason(row) {
    this.isShowUnderReason = false;
    this.isShowUnderReason = true;
    this.under_reason = row.auth_message;
  }

  selectJDGoods() {
    (this.$refs.goodsSelector as any).select(true).then(() => {
      // 选择完商品后，刷新本页面商品
      this.tableProxy.request();
    });
  }

  createGoods() {
    this.$router.push({
      name: "goodPublish",
      params: {
        callback: () => this.tableProxy.request(),
      } as any,
    });
  }

  importData() {
    (this.$refs.excelUploader as any).handleUpload();
  }

  openImportDialog() {
    this.importShow = true;
  }

  closeImportDialog() {
    this.importShow = false;
  }

  async handleImportedData({ results }) {
    await $goodsListTemplate.importTemplate(results);
    this.tableProxy.reset()
  }

  getJdFileState() {
    if (this.jdImportTimer) clearTimeout(this.jdImportTimer);
    API_goods.exportJdGoodsState().then((res) => {
      const { code, message } = res;
      if (code !== 200) return;
      if (message === "COMPLETE") {
        this.jdImportLoading = false;
      } else {
        this.jdImportTimer = setTimeout(() => {
          this.getJdFileState();
        }, 1000);
      }
    });
  }

  handleImportedFileData() {
    const loader: any = this.$refs.excelUploader as any;
    if (this.fileList.length <= 0) return this.$message.error("请上传文件数据");
    const firstFile: any = this.fileList[0];
    const ext = firstFile.name
      .substring(firstFile.name.lastIndexOf(".") + 1)
      .toLowerCase();
    if (ext !== "xlsx") {
      return this.$message.error("上传的文件格式不正确，请重新上传文件");
    }
    loader.getData(firstFile).then(({ header, results }) => {
      if (header[0] !== this.jdImportName) {
        return this.$message.error(
          "导入的数据格式与模板不一致，请核验后重新上传导入"
        );
      }
      if (results.length) {
        results = results
          .filter((item) => item[header[0]])
          .map((item) => item[`${this.jdImportName}`]);
      }
      if (results.length <= 0) {
        return this.$message.error("导入的数据至少有一条！");
      }
      if (results.length > 50000) {
        return this.$message.error("当前文件内数据超过5万条的最大限制数");
      }
      API_goods.exportJdGoods({
        extGoodsIds: [...results],
      })
        .then((res) => {
          const { code, message } = res;
          if (code !== 200) {
            return this.$message.error(message);
          }
          // 按钮提示等待
          this.jdImportLoading = true;
          this.getJdFileState();
          // 关闭弹框
          this.closeImportDialog();
          // 调用接口
          this.$alert("文件上传成功，数据生成中，请耐心等待..");
        })
        .catch(() => {
          return this.$message.error("上传失败请重试");
        });
    });
  }

  handleDataExport(data: GoodsItemProxy[]) {
    if (!data || !data.length) return this.$message.error("请勾选至少一件商品");
    exportSheet(
      {
        name: "",
        values: data.map((goods) => {
          const item = goods.raw;
          let obj: any = {
            商品编号: item.sn,
            商品名称: item.goods_name.replaceAll('amp;', '').replaceAll(' ', '&nbsp;'),
            商品分类: item.cat_name,
            市场价: Foundation.formatPrice(item.mktprice),
            供货价: Foundation.formatPrice(item.cost),
            "总库存(件)": item.quantity,
            "总销量(件)": item.buy_count,
            创建时间: new Date(item.create_time * 1000),
            在售状态: goods.saleStatusText,
            审核状态: goods.authStatusText,
          };
          if ((this as any).MixinIsEownSupplier) {
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { 审核状态, ...reg } = obj
            reg.创建时间 = unixToDate(reg.创建时间 / 1000, 'yyyy-MM-dd hh:mm')
            obj = reg
          }
          return obj
        }),
      },
      `商品列表-${Date.now()}`
    );
  }

  exportData() {
    // return console.log(this.tableProxy.select, this.tableProxy.select.selectedData, this.tableProxy.select.isAllSelectedMode)
    // 如果商品总数小于商品单页容量
    if (this.tableProxy.count < this.tableProxy.pageSize) {
      // 则直接走本地筛选
      const selectedData = this.tableProxy.exportSelectedData(true);

      this.handleDataExport(selectedData);
    } else {
      // 如果商品总数大于商品单页容量
      // 并且现在是全选模式
      if (this.tableProxy.select.isAllSelectedMode) {
        // 则拿出不选择的数据
        const unselectedData = this.tableProxy.exportUnselectedData();
        if (unselectedData.length === this.tableProxy.count) {
          return this.$message.error("请勾选至少一件商品");
        }
        // 然后通过接口拿到选择的数据
        $goodsList
          .exportData({
            excludeGoodsId: unselectedData.map((d) => d.id),
          })
          .then((resp) => {
            this.handleDataExport(
              resp.data.map((item) => new GoodsItemProxy(item))
            );
          });
      } else {
        // 如果不是全选模式
        // 则直接把已选择的数据全都拿出来就可以了
        const selectedData = this.tableProxy.exportSelectedData();
        this.handleDataExport(selectedData);
      }
    }
  }

  downloadTemplate() {
    $goodsListTemplate.exportTemplate();
  }

  jdDownloadTemplate() {
    $goodsListTemplate.exportTemplate([], "表格模板", {
      fields: [
        {
          name: this.jdImportName,
          key: "sku",
        },
      ],
    });
  }

  handlerBatchDown() {
    const data = this.tableProxy.exportData();
    if (!data.length) return this.$message.error("请至少勾选一件商品");
  }

  handlerBatchDelete() {
    const data = this.tableProxy.exportData();
    if (!data.length) return this.$message.error("请至少勾选一件商品");
  }

  fileUpload(e) {
    const { files } = e.target;
    const file = files[0] as never;
    if (file) {
      this.fileList.length = 0;
      this.fileList.push(file);
      e.target.value = "";
    }
  }

  clearFile() {
    this.fileList.length = 0;
  }
}
